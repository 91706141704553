import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const HandArea = ({ cards, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, '手札'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div className='hand-container'>

            <div ref={drop} style={{
                transform: "rotateX(-30deg)", padding: '2rem 1rem', margin: '0.5rem 4rem', backgroundColor: isOver ? 'lightgreen' : '#ccc', border: '', minHeight: '100px', textAlign: 'center', height: "18vh"
            }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }
                } >
                    {
                        cards.map((card) => (
                            <Card key={card.id} id={card.id} card={card} onClick={() => { }} />
                        ))
                    }
                </div >
            </div >
        </div>
    );
};

export default HandArea;
