import React, { useState } from 'react';

const CategoryFilterButtons = ({ allCategories = ["少年探偵団", "探偵", "毛利探偵事務所", "怪盗"], selectedCategory, onCategoryChange, onReset }) => {
    // const allCategories = ["少年探偵団", "探偵", "毛利探偵事務所", "怪盗", "警察", "犯人", "被害者", "目撃者", "証人"];
    const initialCategories = 6; // 最初に表示するカテゴリ数

    const [visibleCategories, setVisibleCategories] = useState(initialCategories);
    const [expanded, setExpanded] = useState(false);

    const showMoreCategories = () => {
        setVisibleCategories(allCategories.length);
        setExpanded(true);
    };

    const showLessCategories = () => {
        setVisibleCategories(initialCategories);
        setExpanded(false);
    };

    return (
        <div>
            {allCategories.slice(0, visibleCategories).map(category => (
                <button
                    key={category}
                    className="item-color search-button"
                    onClick={() => onCategoryChange(category)}

                    style={{
                        backgroundColor: selectedCategory.includes(category) ? '#000' : '#fff',
                        color: selectedCategory.includes(category) ? '#fff' : '#000',
                        fontWeight: 700
                    }}
                >
                    {category}
                </button>
            ))}
            {expanded ? (
                <button onClick={showLessCategories} style={{
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    border: 'none',
                    color: 'grey',
                    cursor: 'pointer',
                    padding: '0',
                    margin: '10px 5px 0 12px',
                    fontSize: '1.4rem'
                }}
                >
                    表示を減らす
                </button>
            ) : (
                <button onClick={showMoreCategories} style={{

                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    border: 'none',
                    color: 'grey',
                    cursor: 'pointer',
                    padding: '0',
                    margin: '10px 5px 0 12px',
                    fontSize: '1.4rem'
                }}
                >
                    もっと表示する
                </button>
            )}
        </div>
    );
};

export default CategoryFilterButtons;
