import React, { useState } from 'react';

const FeatureDetailFilterButtons = ({ selectedFeatureDetail, onFeatureDetailChange, onReset }) => {

    const [expanded, setExpanded] = useState(false);


    const showMoreCategories = () => {
        setExpanded(true);
    };

    const showLessCategories = () => {
        setExpanded(false);
    };
    const category = ["APを上げる", "APを下げる", "LPを上げる", "LPを下げる"];


    const category2 = ["手札を引く", "手札を捨てさせる"];
    const category3 = ["アクティブに戻す", "スリープさせる", "スタンさせる"];
    const category4 = ["カードをセットする", "セットされているカードをリムーブする"]
    const category5 = ["キャラを登場させる", "キャラをリムーブする", "デッキの下に戻す"]


    return (
        <div>
            <div style={{ margin: "4px 0 2px 4px" }}>
                <p>状態系</p>
                {category3.map(category => (
                    <button
                        key={category}
                        className="item-color search-button"
                        onClick={() => onFeatureDetailChange(category)}
                        style={{
                            backgroundColor: selectedFeatureDetail.includes(category) ? '#000' : '#fff',
                            color: selectedFeatureDetail.includes(category) ? '#fff' : '#000',
                            fontWeight: 700
                        }}
                    >
                        {category}
                    </button>

                ))}



                {
                    expanded && (
                        <div>
                            <div style={{ margin: "4px 0 2px 4px" }}>
                                <p>手札系</p>
                                {category2.map(category => (
                                    <button
                                        key={category}
                                        className="item-color search-button"
                                        onClick={() => onFeatureDetailChange(category)}
                                        style={{
                                            backgroundColor: selectedFeatureDetail.includes(category) ? '#000' : '#fff',
                                            color: selectedFeatureDetail.includes(category) ? '#fff' : '#000',
                                            fontWeight: 700
                                        }}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                            <div style={{ margin: "4px 0 2px 4px" }}>
                                <p>AP/LP系</p>

                                {category.map(category => (
                                    <button
                                        key={category}
                                        className="item-color search-button"
                                        onClick={() => onFeatureDetailChange(category)}
                                        style={{
                                            backgroundColor: selectedFeatureDetail.includes(category) ? '#000' : '#fff',
                                            color: selectedFeatureDetail.includes(category) ? '#fff' : '#000',
                                            fontWeight: 700
                                        }}
                                    >
                                        {category}
                                    </button>
                                ))}

                            </div>

                            <div style={{ margin: "4px 0 2px 4px" }}>
                                <p>登場/リムーブ系</p>

                                {category5.map(category => (
                                    <button
                                        key={category}
                                        className="item-color search-button"
                                        onClick={() => onFeatureDetailChange(category)}
                                        style={{
                                            backgroundColor: selectedFeatureDetail.includes(category) ? '#000' : '#fff',
                                            color: selectedFeatureDetail.includes(category) ? '#fff' : '#000',
                                            fontWeight: 700
                                        }}
                                    >
                                        {category}
                                    </button>
                                ))}

                            </div>
                            <div style={{ margin: "4px 0 2px 4px" }}>
                                <p>セット系</p>

                                {category4.map(category => (
                                    <button
                                        key={category}
                                        className="item-color search-button"
                                        onClick={() => onFeatureDetailChange(category)}
                                        style={{
                                            backgroundColor: selectedFeatureDetail.includes(category) ? '#000' : '#fff',
                                            color: selectedFeatureDetail.includes(category) ? '#fff' : '#000',
                                            fontWeight: 700
                                        }}
                                    >
                                        {category}
                                    </button>
                                ))}

                            </div>
                        </div>

                    )
                }
                {
                    expanded ? (
                        <button onClick={showLessCategories} style={{
                            backgroundColor: 'transparent',
                            textDecoration: 'underline',
                            border: 'none',
                            color: 'grey',
                            cursor: 'pointer',
                            padding: '0',
                            margin: '10px 5px 0 12px',
                            fontSize: '1.4rem'
                        }}
                        >
                            表示を減らす
                        </button>
                    ) : (
                        <button onClick={showMoreCategories} style={{

                            backgroundColor: 'transparent',
                            textDecoration: 'underline',
                            border: 'none',
                            color: 'grey',
                            cursor: 'pointer',
                            padding: '0',
                            margin: '10px 5px 0 12px',
                            fontSize: '1.4rem'
                        }}
                        >
                            もっと表示する
                        </button>
                    )
                }
            </div>

        </div >

    );
};

export default FeatureDetailFilterButtons;