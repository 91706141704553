import React, { useState } from 'react';

const FilterInput = ({ filter, onFilterChange }) => {
    const [isComposing, setIsComposing] = useState(false);

    const handleChange = (e) => {
        if (!isComposing) {
            onFilterChange(e.target.value);
        }
    };

    const handleCompositionStart = () => {
        setIsComposing(true);
    };

    const handleCompositionEnd = (e) => {
        setIsComposing(false);
        onFilterChange(e.target.value); // 変換確定時にもフィルタを更新
        // onFilterCommit(); // フィルタリングを実行
    };

    return (
        <input
            type="text"
            className="text"
            id="textField"
            name="textField"
            // value={filter}
            onChange={handleChange}
            onCompositionStart={handleCompositionStart}
            onCompositionEnd={handleCompositionEnd}
        />
    );
};

export default FilterInput;
