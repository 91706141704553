import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import FilterInput from './component/search/FilterInput.js';
import ColorFilterButton from './component/search/ColorFilterButton.js';
import CostFilterButton from './component/search/CostFilterButton.js';
import TypeFilterButton from './component/search/TypeFilterButton.js';
import CategoryFilterButton from './component/search/CategoryFilterButton.js';
// import TitleFilterButton from './component/search/TitleFilterButton.js';
import PackageFilterButton from './component/search/PackageFilterButton.js';
import FeatureCategoryFilterButton from './component/search/FeatureCategoryFilterButton.js';
import FeatureDetailFilterButton from './component/search/FeatureDetailFilterButton.js';

import { searchMap } from './dict.js';
import CardDetail from './component/CardDetail.js';
import Modal from './component/Modal.js';
import Header from './component/Header.js';
import Footer from './component/Footer.js';


const CardList = () => {
    const [data, setData] = useState([]);

    const [filter, setFilter] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedCost, setSelectedCost] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState("");
    const [selectedFeatureCategory, setSelectedFeatureCategory] = useState([]);
    const [selectedFeatureDetail, setSelectedFeatureDetail] = useState([]);
    const [selectedType, setSelectedType] = useState(["キャラ", "イベント"]);

    const [isTileView, setIsTileView] = useState(true);
    const [isDetailSearch, setIsDetailSearch] = useState(false);
    // const [isTitleChecked, setIsTitleChecked] = useState(true);
    // const [isFeatureChecked, setIsFeatureChecked] = useState(true);
    const [sortOrder, setSortOrder] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    const [allPackages, setAllPackages] = useState([]);
    const [allTitles, setAllTitles] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);


    const toggleView = () => {
        setIsTileView(!isTileView);
    };

    const getUniqueId = (data) => {
        const seen = new Set();
        return data.filter(item => {
            if (seen.has(item.card_id)) {
                return false; // 重複している場合は削除
            } else {
                seen.add(item.card_id); // 初めてのIDは追加
                return true;
            }
        });
    };

    useEffect(() => {
        fetch('/card_data.json')
            .then(response => response.json())
            .then(rawData => {
                const uniqueData = getUniqueId(rawData);
                setData(uniqueData);
                const categoryCounts = {};
                uniqueData.forEach(user => {
                    [user.category1, user.category2, user.category3].forEach(categoryField => {
                        if (categoryField) {
                            categoryField.split(',').forEach(category => {
                                category = category.trim();
                                if (category) {
                                    if (!categoryCounts[category]) {
                                        categoryCounts[category] = 0;
                                    }
                                    categoryCounts[category]++;
                                }
                            });
                        }
                    });
                });

                // 出現回数でソート
                const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);
                setAllCategories(sortedCategories);

                const packages = Array.from(new Set(uniqueData.map(user => user.package).filter(Boolean)));
                setAllPackages(packages);


                const titles = Array.from(new Set(uniqueData
                    .filter(user => user.type === "キャラ")
                    .map(user => user.title)
                    .filter(Boolean)
                ));
                setAllTitles(titles);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleFilterChange = (filterValue) => {
        setFilter(filterValue);
    };


    const handleSelectionChange = (selectedItems, setSelectedItems, item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleColorChange = (color) => handleSelectionChange(selectedColors, setSelectedColors, color);
    const handleCostChange = (cost) => handleSelectionChange(selectedCost, setSelectedCost, cost);
    const handleTypeChange = (type) => handleSelectionChange(selectedType, setSelectedType, type);
    const handleCategoryChange = (category) => handleSelectionChange(selectedCategory, setSelectedCategory, category);
    const handleTitleChange = (title) => handleSelectionChange(selectedTitle, setSelectedTitle, title);
    const handlePackageChange = (place) => setSelectedPackage(place);
    const handleFeatureCategoryChange = (category) => handleSelectionChange(selectedFeatureCategory, setSelectedFeatureCategory, category);
    const handleFeatureDetailChange = (category) => handleSelectionChange(selectedFeatureDetail, setSelectedFeatureDetail, category);
    const handleDetailSearch = (bool) => { setIsDetailSearch(bool) }


    const handleColorReset = () => {
        setSelectedColors([]);
    };
    const handleCostReset = () => {
        setSelectedCost([]);
    };
    const handlePackageReset = () => {
        setSelectedPackage("");
    };

    const handleImageClick = (card) => {
        setSelectedCard(card);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCard(null);
    };


    const containsKeywords = (text, keywords) => {
        if (!text) return false;
        return keywords.some(keyword => text.includes(keyword));
    };

    const searchKeyWords = (text, keywords) => {
        if (!text) return false;
        return keywords.some(keyword => text.includes(searchMap[keyword]));
    };

    const filteredData = data.filter(user => {
        const colorMatches = selectedColors.length === 0 || selectedColors.includes(user.color);
        const costMatches = selectedCost.length === 0 || selectedCost.includes(user.cost);
        const packageMatches = selectedPackage.length === 0 || selectedPackage == user.package;
        const categoryMatches = selectedCategory.length === 0 || containsKeywords(user.category1, selectedCategory) || containsKeywords(user.category2, selectedCategory) || containsKeywords(user.category3, selectedCategory);
        const titleMatches = selectedTitle.length === 0 || selectedTitle.includes(user.title);
        const featureCategoryMatches = selectedFeatureCategory.length === 0 || containsKeywords(user.feature, selectedFeatureCategory) || containsKeywords(user.cut_in, selectedFeatureCategory) || containsKeywords(user.hirameki, selectedFeatureCategory);
        const featureDetailMatches = selectedFeatureDetail.length === 0 || searchKeyWords(user.feature, selectedFeatureDetail) || searchKeyWords(user.cut_in, selectedFeatureDetail) || searchKeyWords(user.hirameki, selectedFeatureDetail);
        // const featureDetailMatches = selectedFeatureDetail.length === 0 || (user.feature && user.feature.includes(searchMap[selectedFeatureDetail])) || (user.cut_in && user.cut_in.includes(searchMap[selectedFeatureDetail])) || (user.hirameki && user.hirameki.includes(searchMap[selectedFeatureDetail]));
        const typeMatches = selectedType.length === 0 || selectedType.includes(user.type);

        const filterWords = filter.toLowerCase().split(/[\u3000\s]+/).filter(Boolean);
        const combinedText = `${user.title || ''} ${user.feature || ''}${user.hirameki || ''}${user.cut_in || ''}${user.category1 || ''}${user.category2 || ''}${user.category3 || ''}`.toLowerCase();
        const featureMatches = filterWords.every(word => combinedText.includes(word));

        // const textMatches = isTitleChecked && user.title && user.title.toLowerCase().includes(filter.toLowerCase());
        // const featureMatches = isFeatureChecked && user.feature && user.feature.toLowerCase().includes(filter.toLowerCase());
        return colorMatches && costMatches && categoryMatches && typeMatches && featureMatches && featureCategoryMatches && featureDetailMatches && packageMatches && titleMatches;
    });

    const sortData = (data, sortOrder) => {
        const sortedData = [...data];
        const sortColor = {
            "青": 0,
            "白": 1,
            "緑": 2,
            "黄": 3,
            "赤": 4,
            "黒": 5

        }
        switch (sortOrder) {
            case 'cost-high':
                return sortedData.sort((a, b) => b.cost - a.cost);
            case 'cost-low':
                return sortedData.sort((a, b) => a.cost - b.cost);
            case 'ap-high':
                return sortedData.sort((a, b) => b.ap - a.ap);
            case 'ap-low':
                return sortedData.sort((a, b) => a.ap - b.ap);
            case 'lp-high':
                return sortedData.sort((a, b) => b.lp - a.lp);
            case 'lp-low':
                return sortedData.sort((a, b) => a.lp - b.lp);
            case 'color':
                return sortedData.sort((a, b) => sortColor[a.color] - sortColor[b.color]);
            case 'date-new':
                return sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
            case 'date-old':
                return sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
            default:
                return sortedData;
        }
    };

    const sortedData = sortData(filteredData, sortOrder);

    const resetAllStates = () => {
        setFilter('');
        setSelectedColors([]);
        setSelectedCost([]);
        setSelectedCategory([]);
        setSelectedTitle([]);
        setSelectedPackage("");
        setSelectedFeatureCategory([]);
        setSelectedFeatureDetail([]);
        setSelectedType(["キャラ", "イベント"]);
        setSortOrder('');
        setIsModalOpen(false);
    };

    const handleCategoryClick = (category) => {
        resetAllStates();
        setSelectedCategory([category]);
    };
    const handleTitleClick = (title) => {
        resetAllStates();
        setSelectedTitle([title]);
    };


    const renderTextWithLinks = (text, categories, titles) => {
        if (!text) return null;

        const elements = [];
        let remainingText = text;

        while (remainingText.length > 0) {
            let matchFound = false;
            if (text.includes('特徴')) {
                for (const category of categories) {
                    if (remainingText.startsWith(category)) {
                        elements.push(
                            <span key={elements.length} onClick={() => handleCategoryClick(category)} style={{ color: 'blue', cursor: 'pointer' }}>
                                {category}
                            </span>
                        );
                        remainingText = remainingText.slice(category.length);
                        matchFound = true;
                        break;
                    }
                }
            }
            if (text.includes('絆') || text.includes('カード名'))
                if (!matchFound) {
                    for (const title of titles) {
                        if (remainingText.startsWith(title)) {
                            elements.push(
                                <span key={elements.length} onClick={() => handleTitleClick(title)} style={{ color: 'green', cursor: 'pointer' }}>
                                    {title}
                                </span>
                            );
                            remainingText = remainingText.slice(title.length);
                            matchFound = true;
                            break;
                        }
                    }
                }

            if (!matchFound) {
                elements.push(<span key={elements.length}>{remainingText[0]}</span>);
                remainingText = remainingText.slice(1);
            }
        }

        return elements;
    };


    return (
        <div className="App" style={{}}>
            <Helmet>
                <title>カード検索 コナンTCGファンサイト</title>
                <meta property="og:title" content="名探偵コナントレーディングカードゲームのカード検索ページです。色やコストはもちろん、特徴、特定の能力など、様々な条件で検索することができます。" />
                <meta property="og:description" content="2024年" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="名探偵コナンTCG ファンサイト" />
            </Helmet>
            <Header />
            <Modal isOpen={isModalOpen} renderTextWithLinks={renderTextWithLinks} onClose={handleCloseModal} card={selectedCard} allCategories={allCategories} allTitles={allTitles} />
            <div className="body">

                <div className='searchContainer'>
                    <div className="container" style={{ justifyContent: "center" }}>
                        <h3>検索条件</h3>
                    </div>
                    <div className="container">
                        <div className="category">
                            <p>フリーワード</p>
                        </div>

                        <div>
                            <div style={{ marginLeft: '4px' }}>
                                <FilterInput filter={filter} onFilterChange={handleFilterChange} />
                            </div>
                            {/* <div className="flex">
                                <div>
                                    <input
                                        style={{ marginLeft: '4px' }}
                                        type="checkbox"
                                        id="checkBox"
                                        name="checkBox"
                                        checked={isTitleChecked}
                                        onChange={(e) => setIsTitleChecked(e.target.checked)}
                                    />
                                    <label htmlFor="checkBox" style={{ fontSize: "1.6rem" }}>カード名</label>
                                </div>
                                <div>
                                    <input
                                        style={{ marginLeft: '24px' }}
                                        type="checkbox"
                                        id="checkBox2"
                                        name="checkBox2"
                                        checked={isFeatureChecked}
                                        onChange={(e) => setIsFeatureChecked(e.target.checked)}
                                    />
                                    <label htmlFor="checkBox2" style={{ fontSize: "1.6rem" }}>テキスト</label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="container">
                        <div className="category">
                            <p>種類</p>
                        </div>
                        <TypeFilterButton
                            selectedType={selectedType}
                            onTypeChange={handleTypeChange}
                        />
                    </div>


                    <div className="container">
                        <div className="category">
                            <p>色</p>
                        </div>
                        <div className='category-content'>

                            <ColorFilterButton
                                selectedColors={selectedColors}
                                onColorChange={handleColorChange}
                                onReset={handleColorReset}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div className="category">
                            <p>コスト</p>
                        </div>
                        <div>

                            <CostFilterButton
                                selectedCost={selectedCost}
                                onCostChange={handleCostChange}
                                onReset={handleCostReset}
                            />
                        </div>
                    </div>
                    {isDetailSearch && (<div>

                        <div className="container">
                            <div className="category">
                                <p>効果</p>
                            </div>
                            <div className='category-content'>
                                <FeatureCategoryFilterButton
                                    allCategories={allCategories}
                                    selectedFeatureCategory={selectedFeatureCategory}
                                    onFeatureCategoryChange={handleFeatureCategoryChange}
                                />
                            </div>
                        </div>
                        <div className="container">
                            <div className="category">
                                <p>詳細</p>
                            </div>
                            <div className='category-content'>
                                <FeatureDetailFilterButton
                                    selectedFeatureDetail={selectedFeatureDetail}
                                    onFeatureDetailChange={handleFeatureDetailChange}
                                />
                            </div>
                        </div>
                        <div className="container">
                            <div className="category">
                                <p>特徴</p>
                            </div>
                            <div className='category-content'>

                                <CategoryFilterButton
                                    allCategories={allCategories}
                                    selectedCategory={selectedCategory}
                                    onCategoryChange={handleCategoryChange}
                                />
                            </div>
                        </div>
                        {/* <div className="container">
                  <div className="category">
                    <p>カード名</p>
                  </div>
                  <div className='category-content'>
    
                    <TitleFilterButton
                      allTitles={allTitles}
                      selectedTitle={selectedTitle}
                      onTitleChange={handleTitleChange}
    
                    />
                  </div>
                </div> */}
                        <div className="container">
                            <div className="category">
                                <p>収録先</p>
                            </div>
                            <div className='category-content'>

                                <PackageFilterButton
                                    allPackages={allPackages}
                                    selectedPackage={selectedPackage}
                                    onPackageChange={handlePackageChange}
                                    onReset={handlePackageReset}
                                />
                            </div>

                        </div>
                    </div>
                    )}
                    <div style={{ width: "100%", textAlign: "center", marginTop: "2rem" }} >
                        {!isDetailSearch ?
                            <button onClick={() => handleDetailSearch(true)} style={{ backgroundColor: "white", padding: "0.4rem 4rem", border: "0.1rem solid", borderRadius: "1rem" }}>
                                <h5>詳しく検索条件を入力する ＋</h5>
                            </button>
                            :
                            <button onClick={() => handleDetailSearch(false)} style={{ backgroundColor: "white", padding: "0.4rem 4rem", border: "0.1rem solid #ddd", borderRadius: "1rem" }}>
                                <h5>詳しく検索条件を入力する ー</h5>
                            </button>
                        }
                    </div>
                    <div style={{ width: "100%", textAlign: "center", marginTop: "1rem" }} >
                        <button onClick={resetAllStates} style={{ backgroundColor: "#EEE", padding: "0.4rem 4rem", border: "0.1rem solid #ddd", borderRadius: "1rem" }}>
                            <h5 style={{ color: "#444" }}>検索条件を初期化する</h5>
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ maxWidth: isTileView ? "1050px" : "880px", margin: "0 auto" }}>
                <div style={{ margin: "5vh 1rem 2vh 1rem", borderBottom: "0.6rem solid" }}><h1>CardList</h1></div>
                <div style={{ margin: "1vh 0 1vh 0", justifyContent: "space-between", display: "flex", padding: "0 1rem" }}>
                    <select onChange={(e) => setSortOrder(e.target.value)} style={{ fontSize: "1.6rem", border: "0.2rem solid", padding: "0 1rem 0 0.4rem" }}>
                        <option value="">並び順</option>
                        <option value="cost-high">コストが高い順</option>
                        <option value="cost-low">コストが低い順</option>
                        <option value="ap-high">APが高い順</option>
                        <option value="ap-low">APが低い順</option>
                        <option value="lp-high">LPが高い順</option>
                        <option value="lp-low">LPが低い順</option>
                        <option value="date-new">収録が新しい順</option>
                        <option value="date-old">収録が古い順</option>
                        <option value="color">色順</option>
                    </select>
                    <div>
                        <button onClick={toggleView} style={{ padding: "0 2rem", backgroundColor: "#fff", fontWeight: 700, border: "0.2rem solid" }}>
                            <h5>
                                {isTileView ? 'リスト表示' : 'タイル表示'}
                            </h5>
                        </button>
                    </div>
                </div>

                <div style={{ display: isTileView ? 'flex' : 'block', flexWrap: "wrap", justifyContent: "space-around", margin: "1%" }}>
                    {sortedData.map((card, index) => (
                        isTileView ? (
                            <div style={{}} className='flex' key={card.id}>
                                <div style={{ marginBottom: "12px", margin: "1%" }}  >
                                    <img src={`img/${card.main_path}`} alt={card.title} onClick={() => handleImageClick(card)} className='img-tile' onError={(e) => {
                                        if (e.target.src !== 'img/no.png') {
                                            e.target.src = 'img/no.png';
                                        }
                                    }}
                                    />
                                </div>
                            </div>
                        ) : (

                            <div style={{ border: "0.1rem solid #ccc", marginTop: "1rem", padding: "1rem", borderRadius: "1rem", backgroundColor: "#fff", maxWidth: "880rem", margin: "0.8rem auto" }}>
                                <CardDetail card={card} renderTextWithLinks={renderTextWithLinks} allCategories={allCategories} allTitles={allTitles} />
                            </div>

                        )

                    ))
                    }
                </div>
            </div>
            <Footer />
        </div>



    );
}

export default CardList;