import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const RemoveArea = ({ cards, onDrop, player }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, 'リムーブエリア'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} style={{ padding: '1rem 0', margin: '0.5rem', backgroundColor: isOver ? 'lightgreen' : 'lightgray', border: '1px solid black', minHeight: '100px', textAlign: 'center', position: 'relative', height: "16vh", display: 'flex' }}>
            <div style={{ width: "4rem" }}>

                <h3 style={{ transform: "rotate(90deg)", margin: "2rem" }}>Remove</h3>
            </div>
            <div style={{ position: "relative" }}>

                {cards.map((card, index) => (
                    <div key={card.id} style={{ position: 'absolute', top: player == "player1" ? `${index * 1}px` : `${index * -1}px`, left: `${index * 1}px`, zIndex: index }}>
                        <Card id={card.id} card={card} onClick={() => { }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RemoveArea;
