import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const FieldArea = ({ cards, onDrop, onCardDrop, onCardClick }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, '現場'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    // const handleCardClick = (cardId) => {
    //     onCardClick(cardId, '現場');
    // };

    return (
        <div ref={drop} style={{ padding: '1rem', margin: '0.5rem', backgroundColor: isOver ? 'lightgreen' : 'lightgray', border: '1px solid black', minHeight: '100px', textAlign: 'center', height: "16vh" }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {cards.map((card) => (
                    <Card key={card.id} card={card} onClick={onCardClick} onDropCard={onCardDrop} />
                ))}
            </div>
        </div>
    );
};

export default FieldArea;
