import React from 'react';

const TypeFilterButtons = ({ selectedType, onTypeChange, onReset }) => {
    const type = ["キャラ", "イベント", "パートナー", "事件"];

    return (
        <div>
            {type.map(type => (
                <button
                    key={type}
                    className="item search-button"
                    onClick={() => onTypeChange(type)}
                    style={{
                        backgroundColor: selectedType.includes(type) ? '#000' : '#fff',
                        color: selectedType.includes(type) ? '#fff' : '#000',
                        fontWeight: 700
                    }}
                >
                    {type}
                </button>
            ))}
        </div>
    );
};

export default TypeFilterButtons;