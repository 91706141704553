import React from 'react';
import { useState, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Field from './component/Field';
import initialDeck from './component/data/initialDeck';
import './App.css'; // 追加
import initialDeck2 from './component/data/initialDeck2';
import { AreasProvider } from './provider/AreaContext';

const initialAreas = {
    "player1": {
        Deck: initialDeck.slice(),
        現場: [],
        証拠: [],
        ファイルエリア: [],
        パートナーエリア: [],
        事件エリア: [],
        リムーブエリア: [],
        手札: [],
    },
    "player2": {
        Deck: initialDeck2.slice(),
        現場: [],
        証拠: [],
        ファイルエリア: [],
        パートナーエリア: [],
        事件エリア: [],
        リムーブエリア: [],
        手札: [],
    },
};


function Play() {
    const [areas, setAreas] = useState(
        initialAreas);
    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ position: "absolute", width: "100vw", top: "-10vh" }}>
                <div className='main-game-container'>
                    <AreasProvider>
                        <div style={{ transform: "rotateX(30deg)" }}>
                            <div style={{ height: "49vh", transform: "rotate(180deg)", }}>

                                <Field areas={areas} setAreas={setAreas} title="Player Field" player="player1" />
                            </div>
                            <div style={{ height: "2vh" }}></div>
                            <div style={{ height: "49vh", margin: "0 auto" }}>

                                <Field areas={areas} setAreas={setAreas} title="Player Field" player="player2" />
                            </div>
                        </div>
                    </AreasProvider>
                </div>
            </div>
        </DndProvider>

    );
}

export default Play;
