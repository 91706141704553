export const colorMap = {
    '青': '#00008B', // 暗い青
    '緑': '#006400', // 暗い緑
    '白': '#A9A9A9', // 暗い白（灰色）
    '赤': '#8B0000', // 暗い赤
    '黄': '#8B8000', // 暗い黄
    '黒': '#000000',  // 黒
};

export const colorMap2 = {
    '緑,白': 'linear-gradient(135deg, #006400 0%, #006400 50%, #A9A9A9 50%, #A9A9A9 100%)', // 暗い緑
    '緑,赤': 'linear-gradient(135deg, #006400 0%, #006400 50%, #8B0000 50%, #8B0000 100%)', // 暗い緑
    '緑,黄': 'linear-gradient(135deg, #006400 0%, #006400 50%, #8B8000 50%, #8B8000 100%)', // 暗い緑

};

export const searchMap = {
    "APを上げる": "AP＋",
    "APを下げる": "AP－",
    "LPを上げる": "LP＋",
    "LPを下げる": "LP－",

    "手札を引く": "引く",
    "手札を捨てさせる": "相手は手札を1枚",

    "アクティブに戻す": "アクティブにする。",
    "スリープさせる": "スリープさせる。",
    "スタンさせる": "スタンさせ",
    "セットされているカードをリムーブする": "セットされているカードを",
    "カードをセットする": "でセット",
    "キャラを登場させる": "登場させ",
    "キャラをリムーブさせる": "選び、リムーブ",
    "デッキの下に戻す": "選び、デッキ"


}