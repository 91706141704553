const Header = () => {


    return (

        <header className="header">
            <a href="/">
                <div>
                    <h3>Detective Conan Card Game</h3>
                    <h2>名探偵コナンTCG ファンサイト</h2>
                </div>
            </a>
            {/* <div class="header-item">
                <a href="/">
                    <h3>TOP</h3>
                </a>
            </div>
            <div class="header-item">
                <a href="/card-list">
                    <h3>カード検索</h3>
                </a>
            </div> */}
        </header>

    )
};




export default Header;