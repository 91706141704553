import React from 'react';

const FeatureCategoryFilterButtons = ({ selectedFeatureCategory, onFeatureCategoryChange, onReset }) => {
    const category = ["突撃", "迅速", "ブレット", "ミスリード", "カットイン", "変装", "ヒラメキ"];

    return (
        <div>
            {category.map(category => (
                <button
                    key={category}
                    className="item-color search-button"
                    onClick={() => onFeatureCategoryChange(category)}
                    style={{
                        backgroundColor: selectedFeatureCategory.includes(category) ? '#000' : '#fff',
                        color: selectedFeatureCategory.includes(category) ? '#fff' : '#000',
                        fontWeight: 700
                    }}
                >
                    {category}
                </button>
            ))}

        </div>
    );
};

export default FeatureCategoryFilterButtons;