import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const FileArea = ({ cards, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, 'ファイルエリア'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} style={{ padding: '1rem', margin: '0 1%', backgroundColor: isOver ? 'lightgreen' : 'lightgray', border: '1px solid black', minHeight: '100px', textAlign: 'center', width: '72%', height: '16vh' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                {cards.map((card, index) => (
                    <div key={card.id} style={{ position: 'absolute', top: `${index * 0}px`, left: `${index * 2 + 4}rem`, zIndex: index }}>
                        {/* <div style={{ transform: "rotate(90deg)" }}> */}
                        <div >

                            <Card key={card.id} card={card} onClick={() => { }} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileArea;
