import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const EvidenceArea = ({ cards, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, '証拠'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} style={{ padding: '1rem', margin: '0.5rem', backgroundColor: isOver ? 'lightgreen' : 'lightgray', border: '1px solid black', minHeight: '100px', textAlign: 'center', height: "34vh" }}>
            <h3>証拠</h3>
            <div style={{ position: 'relative' }}>

                {cards.map((card, index) => (
                    <div key={index} style={{ position: 'absolute', top: `${index * 22}px`, zIndex: index, width: "100%" }}>
                        <Card key={card.id} card={card} onClick={() => { }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EvidenceArea;
