const initialDeck2 = Array.from({ length: 40 }, (_, i) => ({
    id: i,
    player: "player2",
    isSummoningSick: false,
    card_id: "0175",
    title: "工藤新一",
    card_num: "B02003",
    show_hide: "表示",
    date: "2024-07-19 18:30:00",
    package: "CT-P02 Case-Booster 02 西と東の大決戦（コンタクト）",
    category1: "探偵",
    category2: "高校生",
    category3: null,
    region: "日本",
    main_thumb: "B02003.jpg",
    main_path: "1721357158815837.jpg",
    sub_thumb: null,
    sub_path: null,
    color: "青",
    type: "キャラ",
    rarity: "SR",
    cost: "8",
    ap: "7000",
    lp: "2",
    feature: "【パートナー青】【登場時】レベル7以下のキャラを1枚まで選び、デッキの下に移す。\n【宣言】【ターン1】〚カード名［毛利蘭］〛のキャラを1枚まで選び、ターン終了時までLP＋1する。",
    drawing: "描きおろし",
    flavor_txt: null,
    difficulty_first: null,
    difficulty_second: null,
    illustrator: "水季",
    copyright: "©青山剛昌／小学館  ©TOMY",
    hirameki: null,
    cut_in: null,
    q_a: "{\"自分のパートナーが青でない場合、このキャラの【宣言】能力は有効ですか？\":\"はい。【パートナー（青）】は【登場時】能力にのみ適用されます。\"}",
    linkto: null,
    contain: null,
    henso: null,
    created_at: "2024-07-19T02:44:34.000000Z",
    updated_at: "2024-07-19T02:45:58.000000Z",
    img: "1721357158815837.jpg",
    isFaceUp: false,
    status: 'アクティブ',
    // droppedArea: 'deck'
}));

export default initialDeck2;
