import React from 'react';
import { colorMap, colorMap2 } from '../dict.js';

const CardDetail = ({ card, renderTextWithLinks, allCategories, allTitles }) => {
    return (
        <div>
            <div className="flex" style={{ marginTop: "1rem", padding: "1rem" }} key={card.id}>
                <div className='img-responsive img-pc'>
                    <img src={`img/${card.main_path}`} alt={card.title} className='img-responsive' style={{ objectFit: "cover" }} onError={(e) => {
                        if (e.target.src !== 'img/no.png') {
                            e.target.src = 'img/no.png';
                        }
                    }}
                    />
                </div>
                <div style={{ display: "block", marginLeft: "0rem", width: "100%" }}>
                    <div className="flex2" style={{ justifyContent: " space-between" }}>
                        <div className="flex">
                            {card.color.toString().length > 1 ? (<div style={{
                                borderRadius: '100%',
                                margin: '2px',
                                padding: '2px',
                                height: '28px',
                                width: '28px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: colorMap2[card.color] || '#02499F',

                            }}>
                                <p style={{ color: "#ffffff" }}>{card.cost}</p>
                            </div>) : (<div style={{
                                borderRadius: '100%',
                                backgroundColor: colorMap[card.color] || '#02499F',
                                margin: '2px',
                                padding: '2px',
                                height: '28px',
                                width: '28px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                            }}>
                                <p style={{ color: "#ffffff" }}>{card.cost}</p>
                            </div>)}
                            <div>
                                <h2>{card.card_id} {card.title}</h2>
                            </div>
                        </div>
                        <div className="flex" style={{ marginLeft: "0px", alignItems: "center", whiteSpace: "nowrap" }}>
                            {card.category1 && card.category1.split(',').map((line, index) => (
                                <div key={line} className="item-character" style={{ backgroundColor: colorMap[card.color] || '#02499F', }}><p> {line}</p></div>
                            ))}
                            {card.category2 && card.category2.split(',').map((line, index) => (
                                <div key={line} className="item-character" style={{ backgroundColor: colorMap[card.color] || '#02499F', }}><p> {line}</p></div>
                            ))}
                            {card.category3 && card.category3.split(',').map((line, index) => (
                                <div key={line} className="item-character" style={{ backgroundColor: colorMap[card.color] || '#02499F', }}><p> {line}</p></div>
                            ))}


                        </div>
                    </div>
                    {card.type == "キャラ" && (

                        <div className="flex">
                            <div style={{ marginLeft: "3.2rem" }}>
                                <h3><span style={{ fontWeight: "500" }}>AP: </span>{card.ap}</h3>
                            </div>
                            <div style={{ marginLeft: "12px" }}>

                                <h3><span style={{ fontWeight: "500" }}>LP: </span>{card.lp}</h3>
                            </div>
                        </div>
                    )}
                    <div style={{ display: "flex", marginTop: "2rem" }}>
                        <div className='img-responsive img-phone' style={{}}>
                            <img src={`img/${card.main_path}`} alt={card.title} className='img-responsive' onError={(e) => {
                                if (e.target.src !== 'img/no.png') {
                                    e.target.src = 'img/no.png';
                                }
                            }}
                            />
                        </div>


                        <div style={{}}>
                            {card.feature && (<h3>能力</h3>)}
                            {card.feature && card.feature.split('\n').map((line, index) => (
                                <p key={index} style={{ marginBottom: "16px" }}>
                                    <React.Fragment key={index}>
                                        {renderTextWithLinks(line, allCategories, allTitles)}
                                        <br />
                                    </React.Fragment>
                                </p>
                            ))}
                            {card.cut_in && (<h3>カットイン</h3>)}
                            <p style={{ marginBottom: "16px" }}>
                                {card.cut_in && card.cut_in.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {renderTextWithLinks(line.replace(/^【カットイン】/, ""), allCategories, allTitles)}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                            {card.hirameki && (<h3>ヒラメキ</h3>)}
                            <p style={{}}>
                                {card.hirameki && card.hirameki.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {renderTextWithLinks(line.replace(/^【ヒラメキ】（証拠からリムーブされるときに発動する）/, "").replace(/^【ヒラメキ】/, ""), allCategories, allTitles)}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    );
};
export default CardDetail;
