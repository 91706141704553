import React, { useContext, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import DeckArea from './area/DeckArea';
import FieldArea from './area/FieldArea';
import EvidenceArea from './area/EvidenceArea';
import FileArea from './area/FileArea';
import PartnerArea from './area/PartnerArea';
import CaseArea from './area/CaseArea';
import RemoveArea from './area/RemoveArea';
import HandArea from './area/HandArea';
import initialDeck from './data/initialDeck';
import useFieldLogic from './function/useFieldLogic';
import { AreasContext } from '../provider/AreaContext';
import RuleEngine from '../RuleEngine';

// const initialDeck = Array.from({ length: 40 }, (_, i) => ({ id: i, title: "ssss", isFaceUp: false, status: 'アクティブ' }));

const Field = ({ player }) => {
    const { handleDrop, nextTurn } = useFieldLogic(player);
    // const { areas, setAreas } = useAreas();
    const { areas, setAreas, selectedCard, setSelectedCard, actionMode, setActionMode, turns, setTurns } = useContext(AreasContext);


    const handleCardClick = useCallback((card) => {
        // if (actionMode && selectedCard) {
        //     if (card.ap <= selectedCard.ap) {
        //         setAreas((prevAreas) => {
        //             const newAreas = cloneDeep(prevAreas);
        //             const opponent = card.player

        //             // 相手のキャラをリムーブエリアに移動
        //             newAreas[opponent]['現場'] = newAreas[opponent]['現場'].filter(c => c.id !== card.id);
        //             newAreas[opponent]['リムーブエリア'] = [...newAreas[opponent]['リムーブエリア'], card];

        //             // 状態をリセット
        //             setSelectedCard(null);
        //             setActionMode(false);

        //             return newAreas;
        //         });
        //     } else {
        //         // アクションモードをリセット
        //         setSelectedCard(null);
        //         setActionMode(false);
        //     }
        // }
        // else if (!selectedCard && !actionMode) {

        //     if (card) {
        //         if (card.status == "アクティブ" && card.isSummoningSick == false) {

        //             setSelectedCard(card);
        //         }
        //     }
        // } else {
        //     setSelectedCard(null);
        // }
        setSelectedCard(card)
        setAreas((prevAreas) => {
            const newAreas = cloneDeep(prevAreas);
            const updatedCard = { ...card, status: card.status == 'スリープ' ? 'スタン' : card.status == 'スタン' ? "アクティブ" : "スリープ" };
            newAreas[player]['現場'] = newAreas[player]['現場'].map((c) => c.id === card.id ? updatedCard : c);

            // デッキから証拠エリアに1枚カードを移動
            setSelectedCard(null);
            return newAreas;
        });
    }, [actionMode, selectedCard]);

    const handleReasoning = useCallback(() => {
        if (!selectedCard) return;

        setAreas((prevAreas) => {
            const newAreas = cloneDeep(prevAreas);
            const updatedCard = { ...selectedCard, status: 'スリープ' };
            newAreas[player]['現場'] = newAreas[player]['現場'].map((card) => card.id === selectedCard.id ? updatedCard : card);

            // デッキから証拠エリアに1枚カードを移動
            if (newAreas[player]['Deck'].length > 0) {
                const newCard = newAreas[player]['Deck'].shift();
                newCard.isFaceUp = false;
                newCard.status = 'スリープ';
                handleDrop(newCard, "証拠")
                // newAreas[player]['証拠'] = [...newAreas[player]['証拠'], newCard];
            }
            setSelectedCard(null);
            return newAreas;
        });
        const card = areas[player]['Deck'].shift();
        handleDrop(card, "証拠")

    }, [areas, selectedCard]);

    const actionEvidence = useCallback(() => {
        if (!selectedCard) return;

        setAreas((prevAreas) => {
            const newAreas = cloneDeep(prevAreas);
            const updatedCard = { ...selectedCard, status: 'スリープ' };
            newAreas[player]['現場'] = newAreas[player]['現場'].map((card) =>
                card.id === selectedCard.id ? updatedCard : card
            );

            const opponent = player === "player1" ? "player2" : "player1";

            if (newAreas[opponent]['証拠'].length > 0) {
                const removeCard = newAreas[opponent]['証拠'].shift();
                newAreas[opponent]['リムーブエリア'] = [...newAreas[opponent]['リムーブエリア'], removeCard];
            }

            if (newAreas[player]['Deck'].length > 0) {
                const c = newAreas[player]['Deck'].shift();
                c.isFaceUp = false;
                c.status = 'スリープ';
                newAreas[player]['証拠'] = [...newAreas[player]['証拠'], c];
            }

            setSelectedCard(null);
            return newAreas;
        });
    }, [selectedCard, player]);

    const actionCharacter = () => {
        if (selectedCard) {
            setActionMode(true);
        }
    };


    return (
        <div style={{}}>
            <div style={{ margin: "0 auto" }}>
                <div style={{ display: 'flex', margin: "0 auto" }}>
                    <div style={{ width: "18%" }}>
                        <CaseArea cards={areas[player]['事件エリア']} onDrop={handleDrop} />
                        <EvidenceArea cards={areas[player]['証拠']} onDrop={handleDrop} />
                    </div>
                    <div style={{ width: "64%" }}>
                        <FieldArea cards={areas[player]['現場']} onDrop={handleDrop} onCardClick={handleCardClick} />
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <FileArea cards={areas[player]['ファイルエリア']} onDrop={handleDrop} />
                            <PartnerArea cards={areas[player]['パートナーエリア']} onDrop={handleDrop} onCardClick={handleCardClick} />
                        </div>
                        <HandArea cards={areas[player]['手札']} onDrop={handleDrop} />
                    </div>
                    <div style={{ width: "18%" }}>
                        <DeckArea cards={areas[player]['Deck']} onDrop={handleDrop} player={player} />
                        <RemoveArea cards={areas[player]['リムーブエリア']} onDrop={handleDrop} player={player} />
                        <button onClick={() => nextTurn({ player: player })}>次のターン</button>
                        {selectedCard && turns.currentPlayer == player && (
                            <div>
                                <button
                                    style={{}}
                                    onClick={handleReasoning}
                                >
                                    推理する
                                </button>
                                <button
                                    style={{}}
                                    onClick={actionEvidence}

                                >
                                    事件にアクションする
                                </button>
                                <button
                                    style={{}}
                                    onClick={actionCharacter}
                                >
                                    キャラにアクションする
                                </button>
                            </div>
                        )}
                    </div>
                </div> </div>
        </div>
    );
};

export default Field;
