import React from 'react';
import { useDrag } from 'react-dnd';

const Card = ({ id, card, onClick, isAlwaysRotated, style }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'CARD',
        item: { ...card },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const getRotation = () => {
        if (isAlwaysRotated) return 'rotate(90deg)';
        if (card.status === 'スリープ') return 'rotate(90deg)';
        if (card.status === 'スタン') return 'rotate(180deg)';
        return 'rotate(0deg)';
    };

    return (
        <div
            ref={drag}
            onClick={() => onClick && onClick(card)}
            style={{
                ...style,
                opacity: isDragging || card.isSummoningSick ? 0.5 : 1,
                padding: '0.5rem',
                margin: '0.5rem auto',
                backgroundColor: card.isFaceUp ? 'pink' : 'gray',
                border: '1px solid black',
                cursor: onClick ? 'pointer' : 'move',
                width: '70px',
                aspectRatio: '63/88',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transform: getRotation(),
            }}
        >
            {card.isFaceUp ? card.title : '裏'}
        </div>
    );
};

export default Card;
