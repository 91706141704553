import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import React, { useEffect, useState } from 'react';

import CardList from './CardList.js';
import Home from './Home.js';
import Privacy from './terms/Privacy.js';
import Term from './terms/Term.js';
import Disclaimer from './terms/Disclaimer.js';
import Inquiry from './terms/Inquiry.js';
import Play from './Play.js';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<CardList />} />
        {/* <Route path="/card-list" element={<CardList />} /> */}
        <Route path="/play" element={<Play />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms" element={<Term />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/inquiry" element={<Inquiry />} />
      </Routes>
    </Router>
  );
}

export default App;
