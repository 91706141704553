import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const CaseArea = ({ cards, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, '事件エリア'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div>
            <div ref={drop} style={{ padding: '1rem', margin: '0.5rem', backgroundColor: isOver ? 'lightgreen' : 'lightgray', border: '1px solid black', minHeight: '100px', textAlign: 'center', height: "16vh" }}>
                {/* <h3>事件エリア</h3> */}
                {cards.map((card) => (
                    <Card key={card.id} card={card} onClick={() => { }} />
                ))}
            </div>
        </div>
    );
};

export default CaseArea;
