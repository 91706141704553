import React from 'react';

const ColorFilterButtons = ({ selectedColors, onColorChange, onReset }) => {
    const colors = ['青', '緑', '白', '赤', '黄', '黒'];

    return (
        <div>
            {colors.map(color => (
                <button
                    key={color}
                    className="item-color search-button"
                    onClick={() => onColorChange(color)}
                    style={{
                        backgroundColor: selectedColors.includes(color) ? '#000' : '#fff',
                        color: selectedColors.includes(color) ? '#fff' : '#000',
                        fontWeight: 700
                    }}
                >
                    {color}
                </button>
            ))}
            <button className="item-color" onClick={onReset}>リセット</button>
        </div>
    );
};

export default ColorFilterButtons;