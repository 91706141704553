import React, { useState } from 'react';

const PackageFilterButton = ({ allPackages = ["少年探偵団", "探偵", "毛利探偵事務所", "怪盗"], selectedPackage, onPackageChange, onReset }) => {
    // const allPackages = ["少年探偵団", "探偵", "毛利探偵事務所", "怪盗", "警察", "犯人", "被害者", "目撃者", "証人"];
    const initialPackages = 6; // 最初に表示するカテゴリ数


    return (
        <div>
            <select onChange={(e) => onPackageChange(e.target.value)} style={{ fontSize: "1.4rem", padding: "0.4rem 0" }} value={selectedPackage}>
                <option value="" key="0">選択してください</option>
                {allPackages.map(place => (
                    <option key={place} value={place}>{place}</option>
                ))}
            </select>
            <button className="item-cost" onClick={onReset}>リセット</button>


        </div>
    );
};

export default PackageFilterButton;
