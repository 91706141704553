import React from 'react';
import { Helmet } from 'react-helmet';

function Disclaimer() {
    return (
        <div className='body'>
            <Helmet>
                <title>免責事項</title>
            </Helmet>
            <div className='news'>
                <div style={{ borderBottom: "0.2rem solid", marginBottom: "2rem" }}>
                    <h2>免責事項</h2>
                </div>

                <p>当サイトの情報は、正確性や完全性を保証するものではありません。当サイトの利用によって生じたいかなる損害についても、一切の責任を負いません。</p>

                <p>当サイトのリンク先サイトの内容については、各リンク先サイトの運営者の責任によって提供されています。当サイトはリンク先サイトの内容について一切の責任を負いません。</p>

            </div>
        </div>


    );
}

export default Disclaimer;
