import React from 'react';
import Card from '../Card';
import { useDrop } from 'react-dnd';

const PartnerArea = ({ cards, onDrop, onCardClick }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop: (item) => onDrop(item, 'パートナーエリア'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const handleCardClick = (cardId) => {
        onCardClick(cardId, 'パートナーエリア');
    };

    return (
        <div ref={drop} style={{ padding: '1rem', margin: '0 1%', backgroundColor: isOver ? 'lightgreen' : 'lightgray', border: '1px solid black', minHeight: '100px', textAlign: 'center', width: "24%", height: "16vh" }}>
            {cards.map((card) => (
                <Card key={card.id} id={card.id} card={card} onClick={handleCardClick} />
            ))}
        </div>
    );
};

export default PartnerArea;
