import React from 'react';

const CostFilterButtons = ({ selectedCost, onCostChange, onReset }) => {
    const cost = ["1", "2", "3", "4", "5", "6", "7", "8"];

    return (
        <div style={{}}>
            {cost.map(cost => (
                <button
                    key={cost}
                    className="item-cost search-button"
                    onClick={() => onCostChange(cost)}
                    style={{
                        backgroundColor: selectedCost.includes(cost) ? '#000' : '#fff',
                        color: selectedCost.includes(cost) ? '#fff' : '#000',
                        fontWeight: 700

                    }}
                >
                    {cost}
                </button>
            ))}
            <button className="item-cost" onClick={onReset}>リセット</button>
        </div>
    );
};

export default CostFilterButtons;