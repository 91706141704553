const Footer = () => {


    return (
        <footer>
            <div className="footer">
                <div className="footer-content">
                    <a href="/privacy-policy">
                        <div><p>プライバシーポリシー</p></div>
                    </a>
                    <a href="/terms">
                        <div><p>利用規約</p></div>
                    </a>
                    <a href="/disclaimer">
                        <div><p>免責事項</p></div>
                    </a>
                    <a href="/inquiry">
                        <div><p>お問い合わせ</p></div>
                    </a>
                </div>
            </div>
        </footer>

    )
};




export default Footer;