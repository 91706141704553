import React from 'react';
import { Helmet } from 'react-helmet';

function Term() {
    return (
        <div className='body'>
            <Helmet>
                <title>利用規約</title>
            </Helmet>
            <div className='news'>
                <div style={{ borderBottom: "0.2rem solid", marginBottom: "2rem" }}>
                    <h2>利用規約</h2>
                </div>

                <p>この利用規約（以下「本規約」といいます）は、当サイト（以下「当サイト」といいます）の利用に関する条件を定めるものです。本規約に同意の上、当サイトをご利用ください。</p>

                <h3>禁止事項</h3>
                <p>当サイトでは、以下の行為を禁止しています。</p>
                <ul style={{ marginLeft: "2rem" }}>
                    <li>法令または公序良俗に違反する行為</li>
                    <li>他のユーザーまたは第三者の権利を侵害する行為</li>
                    <li>当サイトの運営を妨害する行為</li>
                </ul>

                <h3>免責事項</h3>
                <p>当サイトの情報は、正確性や完全性を保証するものではありません。当サイトの利用によって生じたいかなる損害についても、一切の責任を負いません。</p>

                <h3>変更および通知</h3>
                <p>本規約は、予告なく変更されることがあります。変更後の規約は、当サイトに掲載された時点で効力を生じるものとします。</p>

            </div>
        </div>


    );
}

export default Term;
