import React from 'react';
import { Helmet } from 'react-helmet';

function Privacy() {
    return (
        <div className='body'>
            <Helmet>
                <title>プライバシーポリシー</title>
            </Helmet>
            <div className='news'>
                <div style={{ borderBottom: "0.2rem solid", marginBottom: "2rem" }}>
                    <h2>プライバシーポリシー</h2>
                </div>
                <p>当サイトは、個人情報の保護に努めています。当サイトでは、個人情報を収集することはありません。</p>

                <h3>クッキーの使用</h3>
                <p>当サイトでは、クッキーを使用してユーザー体験を向上させることがあります。クッキーは、ユーザーのコンピュータに保存される小さなデータファイルです。これにより、再訪問時にユーザーを認識することができます。</p>

                <h3>第三者への情報提供</h3>
                <p>当サイトは、法令に基づく場合を除き、第三者に個人情報を提供することはありません。</p>
            </div>
        </div>


    );
}

export default Privacy;
