import React, { createContext, useState, useContext } from 'react';
import initialDeck from '../component/data/initialDeck';
import initialDeck2 from '../component/data/initialDeck2';

export const AreasContext = createContext();

export const AreasProvider = ({ children }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [actionMode, setActionMode] = useState(false);
  const [areas, setAreas] = useState({
    player1: {
      Deck: initialDeck.slice(5),
      現場: [],
      証拠: [],
      ファイルエリア: [],
      パートナーエリア: [],
      事件エリア: [],
      リムーブエリア: [],
      手札: initialDeck.slice(0, 5).map((card) => { const updatedCard = { ...card, isFaceUp: true }; return updatedCard }),
    },
    player2: {
      Deck: initialDeck2.slice(5),
      現場: [],
      証拠: [],
      ファイルエリア: [],
      パートナーエリア: [],
      事件エリア: [],
      リムーブエリア: [],
      手札: initialDeck.slice(0, 5).map((card) => { const updatedCard = { ...card, isFaceUp: true }; return updatedCard })
    },
  });
  const [turns, setTurns] = useState({
    currentPlayer: 'player2', // 現在のターンのプレイヤー
    turnNumber: 1, // 現在のターン数
    phase: 'auto', // 現在のフェーズ（'draw', 'main', 'battle', 'end' など）
  })


  return (
    <AreasContext.Provider value={{ areas, setAreas, selectedCard, setSelectedCard, actionMode, setActionMode, turns, setTurns }}>
      {children}
    </AreasContext.Provider>
  );
};

export const useAreas = () => useContext(AreasContext);
