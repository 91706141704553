
import { useState, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useAreas } from '../../provider/AreaContext';

const useFieldLogic = (player) => {
    const { areas, setAreas } = useAreas();

    const moveCardToArea = useCallback((player, fromArea, toArea) => {
        setAreas((prevAreas) => {
            const newAreas = cloneDeep(prevAreas);

            if (newAreas[player][fromArea].length === 0) return newAreas;

            const card = newAreas[player][fromArea].shift(); // デッキから1枚カードを取得
            newAreas[player][toArea] = [...newAreas[player][toArea], card]; // 証拠エリアにカードを追加

            return newAreas;
        });
    }, [setAreas]);

    const handleDrop = useCallback((card, areaName) => {
        player = card.player
        setAreas((prevAreas) => {
            const newAreas = { ...prevAreas };
            // 全てのエリアからカードを削除
            Object.keys(newAreas[player]).forEach((area) => {
                newAreas[player][area] = newAreas[player][area].filter((c) => c.id !== card.id);
            });

            // 新しいエリアにカードを追加
            const updatedCard = {
                ...card,
                isFaceUp: areaName === '現場' || areaName === 'パートナーエリア' || areaName === '手札',
                status: areaName === 'ファイルエリア' || areaName === '事件エリア' || areaName === '証拠' ? 'スリープ' : 'アクティブ',
                isSummoningSick: areaName === '現場',
                droppingTo: areaName,
                droppingFrom: card.droppingTo,
            };
            newAreas[player][areaName] = [...newAreas[player][areaName], updatedCard];
            return newAreas;
        });
    }, [areas]);

    const removeCard = (player, areaName, cardId) => {
        setAreas((prevAreas) => {
            const newAreas = { ...prevAreas };
            newAreas[player][areaName] = newAreas[player][areaName].filter((c) => c.id !== cardId);
            return newAreas;
        });
    };

    const addCard = (player, areaName, card) => {
        setAreas((prevAreas) => {
            const newAreas = { ...prevAreas };
            newAreas[player][areaName] = [...newAreas[player][areaName], card];
            return newAreas;
        });
    };

    // const handleCardClick = (cardId, areaName) => {
    //     if (areaName !== '現場' && areaName !== 'パートナーエリア') return;
    //     setAreas((prevAreas) => {
    //         const newAreaCards = prevAreas[areaName].map((card) => {
    //             if (card.id === cardId) {
    //                 let newStatus = 'アクティブ';
    //                 if (card.status === 'アクティブ') newStatus = 'スリープ';
    //                 else if (card.status === 'スリープ') newStatus = 'スタン';
    //                 else if (card.status === 'スタン') newStatus = 'アクティブ';
    //                 return { ...card, status: newStatus };
    //             }
    //             return card;
    //         });
    //         return {
    //             ...prevAreas,
    //             [areaName]: newAreaCards,
    //         };
    //     });
    // };

    const nextTurn = useCallback(({ player = "player1" }) => {
        setAreas((prevAreas) => {
            const newAreas = cloneDeep(prevAreas);

            // 手札にプラス1枚
            if (newAreas[player]['Deck'].length > 0) {
                const newCard = newAreas[player]['Deck'].shift();
                newCard.isFaceUp = true;
                newAreas[player]['手札'] = [...newAreas[player]['手札'], newCard];
            }

            // ファイルエリアにプラス2枚
            for (let i = 0; i < 2; i++) {
                if (newAreas[player]['Deck'].length > 0) {
                    const newCard = newAreas[player]['Deck'].shift();
                    newCard.isFaceUp = false;
                    newCard.status = 'スリープ';
                    newAreas[player]['ファイルエリア'] = [...newAreas[player]['ファイルエリア'], newCard];
                }
            }

            // スリープ状態のカードをアクティブに、スタン状態のカードをスリープに
            Object.keys(newAreas[player]).forEach((area) => {
                newAreas[player][area] = newAreas[player][area].map((card) => {
                    if (card.status === 'スリープ' && (card.droppingTo === '現場' || card.droppingTo === 'パートナーエリア')) {
                        return { ...card, status: 'アクティブ' };
                    } else if (card.status === 'スタン') {
                        return { ...card, status: 'スリープ' };
                    }
                    return { ...card, isSummoningSick: false };
                });
            });

            return newAreas;
        });
    }, [setAreas]);


    return {
        areas,
        handleDrop,
        nextTurn,
        removeCard,
        addCard,
        moveCardToArea,
        setAreas
    }



}


export default useFieldLogic;