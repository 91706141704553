import React from 'react';
import { Helmet } from 'react-helmet';

function Inquiry() {
    return (
        <div className='body'>
            <Helmet>
                <title>お問い合わせ</title>
            </Helmet>
            <div className='news'>
                <div style={{ borderBottom: "0.2rem solid", marginBottom: "2rem" }}>
                    <h2>お問い合わせ</h2>
                </div>

                <p>当サイトに関するお問い合わせは、以下の連絡先までお願いいたします。</p>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfqOvOAPmbPC1fQInQIszGyLSKZBBzQNnDQsMowotLqESKfTg/viewform?usp=pp_url" rel="noopener noreferrer" target="_blank"><p>Googleフォーム </p></a>
                {/* <p>問い合わせフォームもご利用いただけます。</p> */}
            </div>
        </div>


    );
}

export default Inquiry;
